.card {
    margin-right: 5px;
    padding: 10px;
    
    height:40px;
    background: #b22222;
    text-align: center;
    /* border-radius: 5px; */
    position: relative;
  }

  @property --angle{
    syntax:"<angle>";
    initial-value:0deg;
    inherits:false;
  }

  .card::after {
    content: "";
    position: absolute;
    height: 120%;
    width: 105%;
    background-image: conic-gradient(from var(--angle), transparent 70%,gray,white);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    padding: 3px;
    /* border-radius: 5px; */
    animation: 3s spin linear infinite;
  }
  
  .card::before {
    filter: blur(1.5rem);
    opacity: 0.5;
  }
  
  @keyframes spin {
    from {
      --angle: 0deg;
    }
    to {
      --angle: 360deg;
    }
  }
  



  @media (max-width: 640px) {

    .card::after {
      width: 118%;
    }


  }

  @media (max-width: 768px) {

    .card::after {
      width: 120%;
    }


  }



  .returnCard::before{
    content:"";
    position :absolute;
    bottom:-10px;
    left:0;
    width:0%;
    height:3px;
    background-color:white;
    transition:all 1s
      }
    
      .returnCard:hover::before{
        width:100%
      }